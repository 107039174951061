import React from 'react';
import { useDropzone } from 'react-dropzone';

export const FileUpload = (props) => {
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        maxFiles: 5,
        accept: {
            'image/*': [],
            'application/msword': [],
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [],
            'application/pdf': [],
            'application/vnd.ms-powerpoint': [],
            'application/rtf': [],
            'text/*': [],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.presentationml.presentation': []
        },
        onDropAccepted: (files) => {
            props.setFileList({ [props.recordField]: files });
        }
    });
    // props.setFileList(acceptedFiles);



    const files = acceptedFiles.map(file => (
        <li key={file.path}>
            {file.path} - {(file.size / (1024 * 1024)).toFixed(2)} MB
        </li>
    ));

    return (
        <section className="container">
            <div {...getRootProps({ className: 'dropzone' })}>
                <input id={props.recordField} name={props.recordField} {...getInputProps()} />
                <p>
                    <svg className="inline-block fill-gray-400"  id="paperclip" enableBackground="new 0 0 511.895 511.895" height="32" viewBox="0 0 511.895 511.895" width="32" xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <path d="m476.804 45.673c-46.789-46.787-122.918-46.787-169.707 0l-276.393 276.393c-40.938 40.938-40.938 107.553 0 148.492 19.832 19.831 46.2 30.754 74.247 30.754s54.415-10.923 74.247-30.754l53.032-53.033 15.911 15.91c2.928 2.928 7.677 2.928 10.606 0l218.057-218.057c46.788-46.787 46.788-122.917 0-169.705zm-223.36 371.852-10.607-10.606 10.606-10.606 10.607 10.607zm212.753-212.754-191.54 191.541-10.607-10.607 191.54-191.539c35.091-35.092 35.091-92.188 0-127.28-35.092-35.089-92.188-35.091-127.279 0l-276.393 276.392c-29.242 29.243-29.242 76.824 0 106.067 29.244 29.242 76.822 29.242 106.066 0l218.058-218.058c23.393-23.394 23.393-61.459 0-84.853-11.333-11.332-26.4-17.573-42.427-17.573s-31.094 6.241-42.427 17.574c-2.929 2.929-2.929 7.678.001 10.606 2.928 2.928 7.677 2.929 10.606-.001 8.499-8.499 19.799-13.18 31.819-13.18s23.32 4.681 31.819 13.181c17.546 17.545 17.546 46.093 0 63.639l-218.056 218.058c-23.393 23.395-61.459 23.395-84.852 0-23.395-23.394-23.395-61.459 0-84.853l53.033-53.033 15.91 15.91c1.464 1.464 3.384 2.196 5.303 2.196s3.839-.732 5.304-2.196l138.507-138.507c2.929-2.93 2.929-7.678 0-10.607-2.93-2.928-7.678-2.928-10.607 0l-111.99 111.991-10.606-10.606 133.201-133.201c14.167-14.168 33.002-21.97 53.035-21.97s38.867 7.802 53.033 21.968c14.165 14.165 21.967 32.999 21.967 53.032 0 20.026-7.797 38.854-21.952 53.017l-218.072 218.072c-16.998 16.999-39.6 26.361-63.64 26.361s-46.642-9.362-63.64-26.36c-35.091-35.092-35.091-92.188 0-127.279l276.394-276.393c20.47-20.471 47.357-30.705 74.246-30.705s53.776 10.234 74.246 30.705c40.939 40.94 40.939 107.553 0 148.491zm-44.339 1.912c1.16-5.805 1.757-11.767 1.757-17.821 0-24.04-9.361-46.641-26.36-63.639-16.999-17-39.6-26.361-63.64-26.361-6.054 0-12.017.597-17.822 1.757l23.126-23.126c29.242-29.242 76.822-29.242 106.064 0 29.243 29.242 29.243 76.823 0 106.065zm-285.087 72.957 10.606 10.606-10.606 10.606-10.607-10.606z" />
                        </g>
                    </svg> {props.label}{props.required ? '*':''}</p>
            </div>
            {files && (
                <div>
                    <ul>{files}</ul>
                </div>
            )}
        </section>
    );
};